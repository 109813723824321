body {
  background: #FFFECF;
}

.quickeasyinsurance-page-wrapper {
  background: #FFFECF;

  nav {
    position: fixed;
    z-index: 100000;
    top: 0;
    background: #FFFECF;
    width: 100%;

    .nav {
      background: #fff;
      height: 122px;
      width: 100%;
      max-width: 1280px;
      margin: 0 auto;
      box-shadow: 2px 0 6px 0 rgba(0,0,0,0.14), -2px 0 6px 0 rgba(0,0,0,0.14);
      position: relative;

      img {
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
        height: 78px;
        width: auto;

      }
    }
  }

  .get-a-quote-section {
    width: 100%;
    max-width: 1280px;
    background: url("../images/G2G-vector-home-1440.png") no-repeat center;
    background-size: cover;
    text-align: center;
    padding-top: 85px;
    padding-bottom: 140px;
    margin: 120px auto 0;
    box-shadow: 2px 0 6px 0 rgba(0,0,0,0.14), -2px 0 6px 0 rgba(0,0,0,0.14);

    h1 {
      font-family: 'Roboto';
      font-size: 78px;
      font-weight: 600;
      line-height: 56px;
      letter-spacing: 1.73px;

      @include max($small) {
        font-size: 50px;
        line-height: 40px;
      }
    }

    h3 {
      font-family: 'Roboto';
      font-size: 26px;
      line-height: 32px;
      letter-spacing: 0.53px;
      margin-bottom: 28px;
    }

    form {
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
      text-align: center;

      @include max($xsmall) {
        max-width: 225px;
      }

      .form-element {
        position: relative;

        .error-message {
          display: none;
          margin-top: 2px;
          color: $light-red;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          position: absolute;
          left: 0;
          bottom: 2px;
        }

        &.show-error {
          .error-message {
            display: block;
          }
          input {
            border: 2px solid $light-red;
          }
        }

        input {
          width: 100%;
          text-align: center;
          font-size: 18px;
          height: 48px;
          margin-bottom: 23px;
        }
      }
    }
  }

  .footnotes-section {
    text-align: center;
    background: white;
    width: 100%;
    max-width: 1280px;
    height: 170px;
    padding: 27px 20px 0;
    margin: 0 auto;
    box-shadow: 2px 0 6px 0 rgba(0,0,0,0.14), -2px 0 6px 0 rgba(0,0,0,0.14), 0 2px 6px 0 rgba(0,0,0,0.14);

    p {
      font-family: $roboto;
      font-size: 9px;
      line-height: 12px;
      letter-spacing: 0.23px;
      color: #322110;
    }
  }
}






.quickeasyinsurance-page-wrapper {


}